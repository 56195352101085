.get_main{
    padding: 2rem 4rem;
  }
  .get_main > h4{
    line-height: 40px;
    font-size: 2rem;
    font-weight: 300;
  }
  .get_main > h2{
    font-weight: 400;
    font-size: 2.5rem;
    color: #3a3a3a;
  }
  .form_cont{
    display: flex;
    padding: 1rem 5rem;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    border: 1px solid gray;
    padding: 0.5rem 1rem;
    width: 300px;
    max-height: 62px;
    position: relative;
  }
  
  .icon {
    padding: 0.5rem;
    color: white;
    width: 40px;
    text-align: center;
    margin: 0 -10px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
  }
  svg.email_svg {
    width: 29px;
  }
  
  svg.phone_svg {
   width: 50px;
   margin: -2px -29px 0 0;
  }
  svg.location_svg {
    width: 26px;
  }
  svg.selrct_s_svg {
    width: 63px;
    margin: 0 -30px 0 -2px;
  }
  svg.grade_svg {
    width: 23px;
    margin: 1px 0 0 0;
  }
  .btn-submit{
    background: #0076BD;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    display: inline-block;
    line-height: 22px;
    padding: 0.6rem 1rem;
    border-radius: 3px;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    border: 0;
  }
  @media(max-width:640px){
    .form_cont{
      padding: 0;
    }
  }
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    user-select:none ;
    
   
}

body{
  
}

.contentmain-cont {
    display: flex;
    gap: 2rem;
    padding: 1rem 2rem;
    position: relative;
}
.sidebar_main{

    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 8px;
    background-color: #081b24;
    position: relative;
    min-width: 250px;
    color: white;
}

.sidebar_main ul{

    list-style: none ;
    background: #081b24;
    width: 100%;
}

.sidebar_main ul li{

    position: relative;
   
}

.sidebar_main ul li a,.sidebar_main ul li .vt-btn,.sidebar_main ul li .abt-btn{

    list-style: none;
    color: white;
    font-weight: 500;
    font-size: 17px;
    cursor: pointer;
    padding: 0.5rem!important;
    margin-top: 0.5rem;
    text-decoration: none;
    position: relative;
    display: block;
    font-style: normal;
   
    width: 85%;

    text-transform: capitalize;
    transition: 0.5s all ease;

}
.sidebar_main ul li a:hover,.sidebar_main ul li .vt-btn:hover,.sidebar_main ul li .abt-btn:hover{

    background: #0076BD;
    color: white;
}
.sidebar_main ul ul{

    position: static;
    
}

 /* .abt-show .show{

    display: block;


} */
.sidebar_main ul ul li{

   margin: 0;
   line-height: 15px;
   padding: 0;
}

.sidebar_main ul ul li a{

  right: 15%;

    font-size: 13px;
}
.sidebar_main  ul li .vt-btn span,.sidebar_main  ul li .abt-btn span{

    position: absolute;
    cursor: pointer;
    transform: translateY(-50%);
    right: 20px;
    top: 50%;
    font-size: 20px;

    transition: transform 0.4s;

}
.sidebar_main  ul li .vt-btn:hover span,.sidebar_main  ul li .abt-btn:hover span{
 

    transform: translateY(-50%) rotate(-180deg);

}
.firsttext h2{

   
    
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 0.25rem!important;
    color: #009EE0;
    letter-spacing: 1px;
   
    

  }

  /* ///donors.css */

 

  /* ////end //// */

  .firsttext p{

    text-align: justify;
    text-justify: inter-word;
    
    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    margin-top: 0.5rem!important;
  }
  .mainpoint .mt-5 {
    margin-top: 1rem!important;
    margin-bottom: 3rem!important;
}
/* 
  .firsttext ul li{


    font-size: 20px;
    font-weight: 500;
    word-spacing: 1px;
  } */


  .mainpoints{

    padding: 1rem;
    margin-left: -1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    border: 1px solid rgb(255, 237, 241);
    box-shadow: 10px 8px 4px rgb(0 0 0 / 25%);
  }

  /* .points{
    box-shadow: 10px 8px 4px rgb(0 0 0 / 25%);
  } */

  .skill{
    margin-left: 8%;
  }

  .text-style{
    font-size: 1.2rem;
    font-weight: bolder;
    color: green;
  }

  .skilll{

    text-align: left;
    width: 107%;
    margin-left: 15%;
  }

  .ll1{
    display: flex;
    align-items: center;
  }
  .ll1style{

    background-color: green;
    color: white;
    width: 6rem;
    height: 6rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(0 0 0) 0px 0px 5px;
    margin-left: 8%;
    margin-top: -11px;
  }

  .logo11{
    width: 4rem;
    height: 6rem;
  }


  .skill1{
    margin-top: 0;
    margin-bottom: 1rem;
  }


  .text-style2{

    font-size: 1.2rem;
    font-weight: bolder;
    text-align: left;
    margin-left: 33%;
    color: purple;
  }


  .ll2{
    background-color: purple;
    color: white;
    width: 6rem;
    height: 6rem;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    box-shadow: rgb(0 0 0) 0px 0px 5px;
    margin-top: 1rem;
  }

  .com-text{

    text-align: left;
    width: 15rem;
    margin-left: 20%;
  }

  .text-style3{
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: left;
  
 
    color: red;
  }
  .text-style4{
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: left;
    /* margin-left: 17%; */
 
    color: purple;
  }
  .text-style5{
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: left;
    /* margin-left: 17%; */
 
    color: green;
  }

  .skill2{

    text-align: left;
    width: 107%;
    margin-left: 10%;
  }

  .ll3{

    background-color: red;
    color: white;
    width: 6rem;
    height: 6rem;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    box-shadow: rgb(0 0 0) 0px 0px 5px;
    margin-left: 20%;

  }
  .ll4{

    background-color: purple;
    color: white;
    width: 6rem;
    height: 6rem;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    box-shadow: rgb(0 0 0) 0px 0px 5px;
    margin-left: 20%;

  }
  .ll5{

    background-color: green;
    color: white;
    width: 6rem;
    height: 6rem;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    box-shadow: rgb(0 0 0) 0px 0px 5px;
    margin-left: 20%;

  }
  .skilll3{
    text-align: left;
    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    margin-top: 0.5rem!important;
    
   

    
  }

  .last-text{

    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    margin-top: 0.5rem!important;
    
    text-align: justify;
    text-justify: inter-word
  }


  @media (max-width:640px) {

    .sidebar_main{

      display: none;
    }
    
  }

  /* .sidenav_mob{
    display: none;
  }
  
  @media(max-width:640px){
    .sidenav_mob{
      display: flex;
      justify-content: space-between;
    }
    .sidenav_list{
      margin-top: 1rem;
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: all 0.2s linear;
    }
    .sidenav_list_visible{
      opacity: 1;
      height: 100%;
      transition: all 0.2s linear;
    }
    .arrow-down{
      transform:rotate(90deg);
    }
    .arrow-up{
      transform: rotate(-90deg);
    }
    .sidebar_main{
      min-width: 88vw;
    }
  } */

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
}

body {

    
    display: flex;
    flex-direction: column;
    min-height: 100%;
 

}
.workshop .container .row{

    display: flex;
    justify-content: center;
    align-items: center;


}

.workshop .row .card .card-img-top{

    padding: 10px;
    border-radius: 15px;
    width: 100%;
    object-fit: cover;

    height: 30vh;
}


.workshop .row .card{
 

    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    margin-bottom: 35px;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    border-radius: 15px;

   
  

}

.workshop .card:hover{

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}




.heading1 {
 
    text-transform: capitalize;
    align-items: center;
    color: #009EE0;
    align-items: center;
    font-weight: 500;
    padding-top: 20px;
    margin: 15px;
    position: relative;
}
.workshop .heading::before{

    content: '';
    position: absolute;
    bottom: -5px;
    left: 20;
    width: 350px;
    height: 4px;
    background: #009EE0;
}
.cc{

    padding: 9px ;
}
.workshop{


    background:#F8F9F9;
    height: auto;
    margin-top: 20px;
    
}






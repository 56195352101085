.ncontact{
    background: #FFFFFF;
   }
   
   
   .ncontact .firstsection{
   
   
       align-items: center;
       display: flex;
       justify-content: space-between;
       margin: 1rem 156px 2rem 127px;
   }
   
   @media (max-width:1311px) {
   
       .ncontact .firstsection{
   
   
           align-items: center;
           display: flex;
           justify-content: space-between;
           margin-left: 45px;
           margin-right: 43px;
       }
       
       
   }
   
   @media  only screen and (max-width:1127px)
   {
       .ncontact .firstsection{
   
   
           align-items: center;
           display: flex;
           justify-content: center;
           flex-direction: column-reverse;
           margin:10px;
           width: 100%;
       }
   }
   .ncontact{
       padding: 15px;
   }
   
   .ncontact  .conhead{
       text-transform: capitalize;
       margin-bottom: 2rem;
       color: black;
       
       font-size: 28px;
       font-weight: 600;
       line-height: 34px;
       position: relative;
   
   
   }
   .ccc{
      text-transform: capitalize;
       margin-bottom: 2rem;
       color: #009EE0;
       
       font-size: 28px;
       font-weight: 600;
       line-height: 34px;
       position: relative;
       margin-top: 10px;
   
   }
   .ccc::before{
   
       content: '';
       position: absolute;
       bottom: -10px;
       left: 47%;
       width: 6%;
       height: 4px;
       background: #009EE0;
   
   
   }
   .ncontact .firstsection .name{
   
       display: flex;
       align-items: center;
       justify-content: space-between;
       margin-bottom: 2rem;
   }
   .ncontact .firstsection .contact-input{
   
       background: #f5f5f5;
       border-radius: 10px;
       height: 60px;
       width: 250px;
       padding-right:40px;
   }
   .ncontact .firstsection .name img{
   
       opacity: .4;
   }
   .input-field{
   
       background: #f5f5f5;
       border-radius: 10px;
       height: 40px;
       padding-right: 9px;
       width: 150px;
       padding-left: 0.5rem;
       border-style: none;
   
   
   }
   .ncontact .firstsection .secondinput{
   
       margin-left: 2rem;
   
       margin-right: 2rem;
       
   }
   
   .form-control{
   
       border-style: none;
       display: block;
       width: 100%;
       padding: 0.375rem 0.75rem;
       font-size: 1rem;
       line-height: 1.5;
       color: #495057;
       background-color: #fff;
       background-clip: padding-box;
       border: 1px solid #ced4da;
       border-radius: 0.25rem;
       transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   }
   .for-btn{
       background: #009EE0;
       border: 0;
       border-radius: 13px;
       box-shadow: 0 0 1px transparent;
       color: #fff;
       font-size: 1.3rem;
       font-weight: 400;
       line-height: 22px;
       padding: 0.6rem 1rem;
       text-transform: capitalize;
       -webkit-transform: perspective(1px) translateZ(0);
       transform: perspective(1px) translateZ(0);
       transition-property: color;
       width: 30%
   
   }
   .btnn{
   
       display: block;
       margin-left: 169px;
       margin-right: auto;
   }
   
    .btnn:hover,.btnn:focus{
   
     
     
       box-shadow: 0 0.5em 0.5em -0.4em #009EE0;
       transform: translateY(-0.25em);
     
   
   
   }
   
   .ncontact .secondsection{
   
       width: 25rem;
       border-radius:0.25rem ;
   } 
   .avtar{
   
       display: inline-flex;
       position: relative;
       padding-bottom:45px ;
   }
   .avtar img{
   
       height: 100%;
       object-fit: cover;
       width: 100%
       
   }
   
   .form-control{
       border: none;
   }
 * {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
  
}

body {

    display: flex;
    flex-direction: column;
    min-height: 100%;
   
    width: 100%;
   
   


}

.header{
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 12px 60px;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    position: sticky;
    top: 0%;
    left: 0;
}

.navbar-list{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
   
}
.header .logo1{
    /* margin-left: 20px; */
  
    height: 52px;
    object-fit: cover;


}
/* .header .logo{
    margin-top: 10px;
    padding-left: 10px;
  
} */


.navbar-list li{

    list-style:none ;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

}
.navbar-list li a{

    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    color: grey;
    transition: all 0.3 ease;
    letter-spacing: 1px;
  

}

@media (max-width: 1058px){

    .navbar-list li a{

        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        color: grey;
        transition: all 0.3 ease;
        letter-spacing: 1px;
      
    
    }


}

.mobile{

    display: none;
    align-items: center;

}
.navbar-list li a:hover,.navbar-list li  .active{

    color: #009EE0;
    

    

}
.sub-menu{

    display: none;
}

.sub-menu1{

    display: none;
}

.navbar-list li:hover .sub-menu{

    display: block;
    position: absolute;
    background: rgba(18, 18, 18, 0.7);
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    /* margin-top: 15px;
    margin-left: 10px; */
    width: 350px;

}
.navbar-list li:hover .sub-menu1{

    display: block;
    position: absolute;
    background: rgba(18, 18, 18, 0.7);
    border: 1px solid #FFFFFF;
    border-radius: 10px;
   
    width: 500px;

}



.navbar-list li:hover .sub-menu ul,.navbar-list li:hover .sub-menu1 ul{

    display: block;
    margin: 10px;
}
.navbar-list li:hover .sub-menu ul li{
    color: white;
    background: transparent;
    text-align: left;
    color: #fff;
    left: -15%;
    margin-bottom: 10px;

    width: 350px;
    

    

}
.navbar-list li:hover .sub-menu1 ul li{
    color: white;
    background: transparent;
    text-align: left;
    color: #fff;
    left: -10%;
    margin-bottom: 10px;
    width: 470px;



}
.navbar-list li:hover .sub-menu ul li a, .navbar-list li:hover .sub-menu1 ul li a{

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1px;
    /* identical to box height */

    text-transform: capitalize;
    

    color: #FFFFFF;

}
.fa-angle-right{

  float: right;

}
.navbar-list li  .active::after,.navbar-list li a:hover::after{

    content: "";
    width: 40%;
    height: 3px;
    background-color: #009EE0;
    position: absolute;
    bottom:-1px;
    left: 28px;
}


#close{
    display: none;
}

@media (max-width: 900px) {

   
       
    .header{

        padding: 12px 20px;
    }
    .navbar-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding:80px 0 0 10px ;
        position: fixed;
        top: 0;
        right: -350px;
        height: 100vh;
        width: 350px;
        transition:  0.3s;
        background-color: #fff;
        box-shadow: 0 4px 60px rgba(0,0,0,0.1);
    
    
       
    }
    
    #mobile-hide{

        right: -350px;
    }
    #mobile-show{

        right: 0px;
    }

    .navbar-list li{

        margin-bottom: 25px;
    }

    .mobile{

        display: flex;
        align-items: center;
    
    }

    .mobile i{

        color: #1a1a1a;
        font-size: 24px;
        padding-left: 20px;
    }

    #close{

        position: absolute;
        top: 30px;
        display: flex;
        left: 30px;
        color: #1a1a1a;
        font-size: 24px;
    }
    
}

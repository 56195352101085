

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
   
}

html{

    height: 100%;
}

body {

  
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-x: hidden;
    width: 100%;
    user-select: none;
    
 

}


.contentmain-cont {
    display: flex;
    gap: 2rem;
    padding: 1rem 2rem;
    position: relative;
}
/* .sidebar_main {
  padding-left: 21.5px;
  padding-right: 15px;
  padding-top: 6px;
  padding-bottom: 8px;
  background-color: #081b24;
  min-width: 250px;
} */

.sidebar_main{

  background: #081B24;
  width: 250px;
  padding: 6px 0px ;
  height: 300px;
} 
.width
{
    width: min-content;
}
.sidenav_mob {
    display: none;
}
.color-white {
    color: white;
}
.contentmain-cont .f-1-2 {
    font-size: 1.2rem;
}
 #sidenav_list a{

    text-decoration: none!important;
       text-decoration: none !important;
    color: black !important;

}

#side,#side2,#side3,#side4,#side5,#side6{

    list-style: none;
    color: white;
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem!important;
   margin-top: 0.5rem;
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    transition: 0.5s all ease;

  
    

}


#side7{
  margin-bottom: 0.5rem!important;
}
.selected {
    background: #0076BD;
}
.sidebar_item {
    transition: all 0.5s ease;
    padding: 0 0.5rem;
}


.dropbtn {
    background: #081B24 ;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width:400px;
    height: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  /* .dropdown:hover .dropbtn {background-color: #3e8e41;} */

/* 
  .aboutus h2{

    
    color: #009EE0;

    font-weight: 500;
    

    position: relative;
  }

  .aboutus h2::before{

    content: '';
    position: absolute;
    bottom: -5px;
    left: 1%;
    width: 10%;
    height: 3px;
    background: #009EE0;
  }
 */

  .firsttext h3{

    font-family: 'Montserrat';
    padding-top: 12px;
  }

  .firsttext p{

    text-align: justify;
    text-justify: inter-word
  }

  .firsttext ul li{


    font-size: 20px;
    font-weight: 500;
    word-spacing: 1px;
  }


  #side:hover,#side2:hover,#side3:hover,#side4:hover,#side5:hover,#side6:hover{

    background: #0076BD;
    color: white;
  }
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
}

body {

  
    display: flex;
    flex-direction: column;
    min-height: 100%;
    text-decoration: none;
 

}
/* footer .content .footimg{

    position: relative;
    
    height: 300px;
   
} */

.footimg{

    height: 300px;
}

@media (max-width:500px) {
    .footimg{

        height: 200px;
        align-items: center;
    }
    
    .flogo{

        display: none;
    }
}
footer{
  
    top: 100%;
    display: flex; 
    justify-content: space-between;
    width: 100vw;
    flex-wrap: wrap;
    margin-top:auto;
   
}

/* footer .content{
 


    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom:0px ;
    padding-top: 16px;
    margin-left: 50px;
    
} */


.quicklinks{

    flex: 1;
    height: 100%;
    width: 100%;
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  



    
}
footer   h2{

    position: relative;
    color: #009EE0;

    font-weight: 500;
    padding-top: 20px;
    margin: 15px;

}

footer  h2::before{

    content: '';
    position: absolute;
    bottom: -10px;
    left: 10%;
    width: 85%;
    height: 2px;
    background: #009EE0;

}
.contact{
    flex: 1;
    height: 100%;
    width: 100%;
    gap:  5.2rem;
    display: flex;
  
    flex-direction: column;
   
}

.contact ul h6{

    font-size: 18px;
    text-transform: capitalize;
    text-align: center;
}

.flogo{

    
    padding-left: 200px;
    
}

.flogo img{

    width: 180px;
}

.quicklinks ul,.contact ul{

    padding-top: 10px;
    padding-left: 10px;
}

.quicklinks ul li, .contact ul li  {

    margin-top: 5px;
    text-decoration: none;
}
.quicklinks ul li a,.contact ul li a{

    color: black;
    display: inline-block;
    transition: all 0.3 ease;
    text-decoration: none;
    font-size: 18px
}
.quicklinks ul li a:hover,.contact ul li a:hover{

    color: #009EE0;

    text-decoration: underline;
}


.copyright{

    width: 100vw;
    height: 3rem;
    background: black;
    color: white;
    text-decoration: none;
    
    
}
.copyright p{


    font-size: 1rem;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    line-height: 3rem;
}
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
}


body {


    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-x: hidden;
    width: 100%;
 

}


.contactus_main {
    margin-top: 8rem;
    padding: 0 10%;
}

.contactheading{

    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
    align-items: center!important;
    text-align: center!important;
}

.con_heading h2{

    position: relative;
    font-size: 3rem;
    font-weight: bold;
    color: #009EE0;
    
}

.con_heading h2::before{

    content: '';
    position: absolute;
    bottom: -8px;
    left: 10%;
    width: 80%;
    height: 4px;
    background: #009EE0;

}
.contactinfo{

    margin-top: 4rem;
    justify-content: space-around!important;
    display: flex!important;
    flex-wrap: wrap!important;
}

.contactinfo .fc,.contactinfo .fc2{


    margin-top: 1.5rem!important;
    
   
}

.contactinfo .fc2{

    display: flex;
    flex-direction: row;
    
}
.contactinfo .fc .ist,.contactinfo .fc2 .ist{

    font-weight: bold;
    font-size: 1.1rem;
    color: #009EE0;



}
.contactinfo .fc2 .znd{
    padding-left: 13px;
    line-height: 10px;
    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    margin-top: 0.5rem !important;
}
.contactinfo .fc .znd,.contactinfo .fc .trd{

    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    margin-top: 0.5rem !important;

}

.detail{

    color: rgba(0,0,0,.61);
    margin-bottom: 2em;
    margin-top: 0.5rem!important;
}

.profiles{

    display: flex;


}

.profile{

    margin-right: 10px;
}

.profile a{

    text-decoration: none !important;
    color: black !important;
}

.contactform{

    margin-top: 1.5rem!important;
}

.form-main{

    margin-bottom: 3em;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #009EE0;
}
.input-container {
    display: flex;
    width: 100%;
    border: 1px solid #009EE0;
    padding: 0.5rem 1rem;
    width: 300px;
    max-height: 62px;
    position: relative;
    margin-top: 1rem!important;
}

.icon{

    padding: 0.5rem;
    
    width: 60px;
    text-align: center;
    margin: 0 -10px
}
.forminput{

    width: 100%;
    padding: 13px;
    outline: none;
    border: none
}

.formconbtn{

    margin-top: 1.5rem!important;
    align-content: center!important;
    justify-content: center!important;
    display: flex!important;
    
}

.btn-submit {
    background: #009EE0;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    display: inline-block;
    line-height: 22px;
    padding: 0.6rem 1rem;
    border-radius: 3px;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    transition-property: color;
    transition-duration: .3s;
    border: 0;
    width: 100%;
}
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
    background: white;
}

body {

    
    display: flex;
    flex-direction: column;
    min-height: 100%;
    
 

}


.branches .row{

    display: flex;
    justify-content: center;
    align-items: center;
}



.text {


    font-weight: 500;
    color: #009EE0;
    
    padding-top: 15px;
    text-decoration: underline;
}
.cc{

    padding: 9px ;
}


.branches .row{

    margin-top: 70px;
}
.branches .card {
    display: inline-block;
    position: relative;
    width: 100%;
    
    margin-bottom: 30px;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
}
.branches .tcard.card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 40px
}

.branches .card:hover{

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.branches .card-body h5{

    color: white;
    /* font-weight: 400; */
}

.branches .card .card-title{

    text-align: center;
    text-transform: capitalize;
    /* line-height: 30px;
    font-weight: 500; */

}
.branches .card .card-text {

    

    text-align: center;
    text-transform: capitalize;
    line-height: 30px;
    /* font-weight: 500; */

    color: white;
}

.branches .card-body span .text {

    text-align: center;
}
.branches .line{

    background: yellow;
}
.branches .first{
    margin-top: 0.5px;

    background:#027A2B
}
.branches .second{
    margin-top: 0.5px;

    background: #F54D17;

}
.branches .third{
    margin-top: 0.5px;

    background: #F53864;


}

.branches .details{
    padding-bottom: 10px;
}

.branches .details .cardbtnf{

    position: relative;
  
    padding: 10px ;
    border: none;
    outline: none;
    color: #fff;
    background: 	#027A2B;
     
     border: 1px solid white;
    font-size: 15px;
    font-weight: 600;
    border-radius:50px;
    color: white;
    font-weight: 500;
    text-decoration: none;
    

    

}

.branches .details .cardbtns{

    position: relative;
  
    padding: 10px ;
    border: none;
    outline: none;
    color: #fff;
    background: 	#F54D17;
     
     border: 1px solid white;
    font-size: 15px;
    font-weight: 600;
    border-radius:50px;

    text-decoration: none;
    

    

}
.branches .details .cardbtnt{

    position: relative;
  
    padding: 10px ;
    border: none;
    outline: none;
    color: #fff;
    background:#F53864;
     
     border: 1px solid white;
    font-size: 15px;
    font-weight: 600;
    border-radius:50px;
    background: transparent;
    text-decoration: none;
    

    

}
.branches .details .cardbtnt:hover,.cardbtnt:focus,.cardbtnf:hover,.cardbtnf:focus,
.cardbtns:hover,.cardbtns:focus,.cardbtnff:hover,.cardbtnff:focus{

    transform: translateY(-0.25em);
    box-shadow: 0 0.5em 0.5em -0.4em white;

     
}

.branches .card .card-img-top{

    width: 100%;
    height: 40vh;
    object-fit: cover;
}


.branches .details .cardbtnff{

    position: relative;
  
    padding: 10px ;
    border: none;
    outline: none;
    color: white;
    background: #EBB701;
     
     border: 1px solid white;
    font-size: 15px;
    font-weight: 600;
    border-radius:50px;

    text-decoration: none;
    

    

}
.branches .details .cardbtnf:hover{

    cursor: pointer;
}
.branches .fourth{

    margin-top: 0.5px;
    background: #EBB701;



}


.branches .details
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 1px;
    color: #fff;
}
.branches .details .contactbranch{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px ;
    margin-top: 14px;
    color: white;
    /* font-weight: 500; */
    

 
}
.contactbranch h6{

    font-weight: 500;
}
.branches .card{

    width: 100%;
}
.branches .card .card-title{
    text-transform: capitalize;


    font-size: 20px;
    
    letter-spacing: 1px;
}
.branches .details .email{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px ;
    margin-bottom: 10px;
    font-weight: 400;
    color: white;
    /* font-weight: 500; */

 
}

.branches{

    background: #F2F4F4;
}
.branches .tt{

    align-items: center;
    color: #009EE0;

    font-weight: 500;
    padding-top: 40px;

    position: relative;
}

.branches .tt::before{

    
    content: '';
    position: absolute;
    bottom: -5px;
    left: 42%;
    width: 16%;
    height: 4px;
    background: #009EE0;

}
.branches .details .contactbranch .num,.branches .details .email .num{

    padding-top: 10px;
    padding-left: 8px;

}

@media (max-width:500px) {

    .col-lg-5 {

        width: 23rem;
    }
    
    .row .card .card-img-top{

        width: 100%;
        height: 20vh;
        object-fit: cover;
    }

    .row .card .card-text{

        text-align: justify;
        
    }
}

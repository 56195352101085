@import url('https://fonts.googleapis.com/css2?family=Anek+Bangla&family=Barlow&family=PT+Sans&family=Roboto+Mono:ital,wght@1,300&display=swap');
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
}

body {

    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-x: hidden;
    width: 100%;
 

}

.contact-us .row{

    display: flex;
    justify-content:center;
    align-items: center;
}
.contact-us .card{


    margin: 1.5rem !important;
    border:  1px solid #009EE0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 35rem;
    width: 100%;


}
@media (max-width:1372px) {

    .contact-us .card{


        margin: 1.5rem !important;
        border:  1px solid #009EE0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        height: 35rem;
    
    
    }
    
    


}

@media (max-width:976px) {
    .contact-us .row .col-lg-4{
        left: -6%;
        width: 24rem
    }
    
}
.contact-us .card:hover{

    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.information{

    display: flex;
    height: 3.2rem;
    
 
    
 
}
.contact-us .card .card-title
{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
/* identical to box height */



color: #121212;
}


.contact-us .card .card-img-top{

    width: 100%;
    
    object-fit: cover;
}
.contact-us .card .card-text{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    font: bold;
    
    
    
    color: #000000;


}
 .heading2{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
   
    
    color: #121212;
    
}

.information p{

    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 17px;


color: #000000;
}


* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}




.infrahead {


    align-items: center;
    color: #009EE0;
    align-items: center;
    font-weight: 500;
    padding-top: 20px;
    margin: 15px;
    position: relative;
}


.infrahead::before{

    content: '';
    position: absolute;
    bottom: -8px;
    left: 37%;
    width: 25%;
    height: 4px;
    background: #009EE0;
} 


.infra .container .row{

    display: flex;
    justify-content: center;
    align-items: center;
}

.infra .row .card{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    border-radius: 15px;
    height: 24rem;
    margin-bottom: 30px;

    
}

.infra .row .card:nth-child(1)
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    border-radius: 15px;
    height: 25rem;
    margin-bottom: 30px;
}
.infra .row .card:nth-child(2)
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    border-radius: 15px;
    height: 25rem;
    margin-bottom: 30px;
}
.infra .row .card:nth-child(3)
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    border-radius: 15px;
    height: 25rem;
    margin-bottom: 30px;
}
.infra .row .card:hover{

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.infra .row .card .card-title{

    color: #009EE0;
}
.infra .row .card .card-img-top{

    padding: 10px;
    border-radius: 15px;
    width: 100%;
    object-fit: cover;

    height: 30vh;
}



#infra-btn{
    transition: transform .3s all;
    text-decoration: none;
   
}
#infra-btn:hover{
    cursor: pointer;
      transform: scale(1);
    text-decoration: underline;
}

#imgrow:last-child .first
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
#imgrow:last-child{
    display: flex;
    justify-content: center;
    align-items: center


}
*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
}

body {

    
    display: flex;
    flex-direction: column;
    min-height: 100%;
 

}

.faridabadcard .container-fluid{

    padding-left: 2rem;
    padding-right: 2rem;
    
}
.faridabadcard{
    background: #F8F9F9;
    height: 100%;


}

.faridabadcard  h2{

    text-transform: capitalize;
    align-items: center;
    color: black;
    align-items: center;
    font-weight: 700;
    padding-top: 20px;
    margin: 15px;
    margin-bottom: 60px;
    position: relative;

}



.faridabadcard .row{

    display: flex;
    justify-content: center;
    align-items: center;
}

.faridabadcard .row .card .card-body{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

   
}

.faridabadcard .row .card .card-img-top{

    width: 100%;
    height: 30vh;
    object-fit: cover;
}



.faridabadcard .row .card .card-text{

    text-align: justify;


    

}

.faridabadcard .row .card{

    width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
    margin-bottom: 30px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.87);
    background: #EBF4FA;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 28rem!important;
    
}

.faridabadcard .row .card:hover{
    box-shadow: rgba(149, 157, 165, 0.8) 0px 8px 24px; 
}
.faridabadcard .info{

    position: relative;
    border-radius:40px;
    border: 1px solid #009EE0;
    padding: 10px ;
    margin-top: auto;
    align-self: center;
    outline: none;
    color: #009EE0;
    
    font-size: 15px;
    font-weight: 600;
    

    text-decoration: none;
}

.faridabadcard .row .card .card-title{

    text-transform: capitalize;
}
.faridabadcard .info:hover,.faridabadcard .info:focus{

    cursor: pointer;
    background: transparent;
    /* color: black;
    border: 2px solid #000080; */
    text-decoration: none;
    box-shadow: 0 0.5em 0.5em -0.4em #009EE0;
    transform: translateY(-0.25em);
}


.bhopal .row .col-lg-4{

    width: 28%;
}
#coll{
    width: 28%;
}

#coll1{
    width: 28%;
}
#coll2{
    width: 28%;
}
#coll3{
    width: 28%;
}
#coll4{
    width: 28%;
}
#coll5{
    width: 28%;
}

@media (max-width: 500px)
{
    #coll
    {
        width:100% ;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #coll1
    {
        width:100% ;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #coll2
    {
        width:100% ;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #coll3
    {
        width:100% ;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #coll4
    {
        width:100% ;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #coll5
    {
        width:100% ;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
/* .fcoursecard{

    background: #F8F9F9;
}
.fcoursecard  h2{

    text-transform: capitalize;
    align-items: center;
    color: black;
    align-items: center;
    font-weight: 700;
    padding-top: 20px;
    margin: 15px;
    margin-bottom: 60px;
    position: relative;

}
.fcoursecard .cc{

    padding: 9px ;
    width: 100%;
    text-align: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
}

.fcoursecard .row .card .card-body{

    margin-bottom: 5px;
}

.fcoursecard .row .card:hover{
    box-shadow: rgba(149, 157, 165, 0.8) 0px 8px 24px; }

.fcoursecard .row .card{
  
   
  
     width: 100%;
   
    margin-bottom: 30px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.87);
    background: #EBF4FA;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
    
    
}




.card{

    height: 100%;
}
.fcoursecard .cc .info{

    

    
  
    position: relative;
    border-radius:40px;
    border: 1px solid #009EE0;
    padding: 10px ;
    
    outline: none;
    color: #009EE0;
    
    font-size: 15px;
    font-weight: 600;
    

    text-decoration: none;
  
}

.fcoursecard .row{

    padding-left: 2rem;
    padding-right: 2rem;

}

.fcoursecard.cc .info:hover{

    cursor: pointer;
    background: transparent;
    color: black;
    border: 2px solid #000080;
    text-decoration: none;


}  */
.mandatory{
   
    margin-left: 25px;
    font-size: 25px;
    font-weight: 700;

    text-transform: capitalize;


    
}

.subhead{
    font-size: 18px;
    font-weight: 500;

    text-transform: capitalize;
    margin-top: 34px;


}
.mandatory_heading{

    font-size: 23px;
    font-weight: 700;
    color: #009EE0;
    margin-left: 15px;

   
  
}
.mandatory_heading1{

    font-size: 23px;
    font-weight: 700;
    color: #009EE0;


   
  
}

@media (max-width:500px){

    .mandatory_heading1{

        font-size: 23px;
        font-weight: 700;
        color: #009EE0;
        display: flex;
        justify-content: center;
        align-items: center;
    
    
       
      
    }
    .mandatory_heading{

        font-size: 23px;
        font-weight: 700;
        color: #009EE0;
        margin-left: -35px;
        display: flex;
        justify-content: center;
        align-items: center;
    
       
      
    }
 

    .subhead{

        margin-top: 20px;
        margin-bottom: 20px;
    }
    .mandatory_table {
        margin-top: 1px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        }
    
}
.contentmain-cont .width{

    width: min-content;
}


.mandatory_table {
margin-top: 1px;

display: flex;
flex-direction: column;
}
.mandatory_list{

    width: 100%;
}
.Table .mandatory_list:nth-child(1) {
    border: 2px solid black;
    background-color: #0076BD;
    color: white;
}
.Table .mandatory_col {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-right: 2px solid black;
    border-bottom: none;
}

@media (min-width: 992px)
{
    .table .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
}
@media (min-width: 992px)
{.col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
}
}

@media (min-width: 992px){
.col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}
}

.mandatory_col_last {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.mandatory_list:nth-child(2) {
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(3) {
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(4) {
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}